import { useNavigate, useParams } from "react-router-dom";

export function useMaybeBusinessProjectId() {
  const { businessId, projectId } = useParams<{
    businessId: string;
    projectId: string;
  }>();

  return { businessId, projectId };
}

export function useBusinessProjectId() {
  const { businessId = "", projectId = "" } = useParams<{
    businessId: string;
    projectId: string;
  }>();

  const navigate = useNavigate();

  if (!businessId === undefined || !projectId === undefined) {
    navigate("/");
  }

  return { businessId, projectId };
}
