import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import dayOfYear from "dayjs/plugin/dayOfYear";
import trunkToolsWhite from "public/trunk_tools_white.svg";
import plusWhite from "public/plus_white.svg";
import runAgentArrow from "public/run_agent_arrow.svg";
import pencilIcon from "public/icon_pencil.svg";
import { Button, GradientBackdrop, Switch } from "@trunk-tools/ui";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import {
  useRunAgent,
  useUserProjectAgentConfigs,
} from "@/dataHooks/agents.dataHook";
import { useState } from "react";
import { FeatureFlagGate } from "@/components/FeatureFlagGate.component";
import { toCronDescriptor } from "@trunk-tools/txt-shared";

dayjs.extend(timezone);
dayjs.extend(dayOfYear);

const AgentRow = ({
  name,
  cron_descriptor,
  active,
  onChangeSwitch,
  onRun,
  onEdit,
}: {
  name: string;
  cron_descriptor: string;
  active: boolean;
  onChangeSwitch: () => void;
  onRun: () => void;
  onEdit?: () => void;
}) => {
  return (
    <div className="flex flex-col gap-y-6 desktop:flex-row desktop:gap-x-6 justify-between p-6 bg-[#222F3852] rounded border border-black">
      <div className="flex flex-row gap-x-6">
        <div className="flex items-center">
          <Switch
            disabled={cron_descriptor === "Never"}
            name={name}
            onChange={onChangeSwitch}
            value={active}
          />
        </div>
        <div className="flex flex-col gap-y-[10px]">
          <div className="text-xl">{name}</div>
          <div className="text-sm">{cron_descriptor}</div>
        </div>
      </div>
      <div className="flex-grow" />
      <div className="w-full desktop:w-fit">
        {onEdit && (
          <Button variant="secondary" onClick={onEdit}>
            <img
              src={pencilIcon}
              alt="run agent"
              className="w-3 h-3 desktop:w-4 desktop:h-4"
            />
            <p className="text-sm uppercase">edit</p>
          </Button>
        )}
      </div>
      <div className="w-full desktop:w-fit">
        <Button variant="success" onClick={onRun}>
          <img
            src={runAgentArrow}
            alt="run agent"
            className="w-3 h-3 desktop:w-4 desktop:h-4"
          />
          <p className="text-sm uppercase">run agent</p>
        </Button>
      </div>
    </div>
  );
};

export const ManageAgentsPage = () => {
  const idNavigate = useIdNavigate();
  const { runAgent } = useRunAgent();
  const { agentConfigs, updateUserProjectAgentConfig, refetchAgentConfigs } =
    useUserProjectAgentConfigs();
  const [agentConfigsActive, setAgentConfigsActive] = useState(
    agentConfigs.agent_configs.reduce<Record<string, boolean>>(
      (acc, agentConfig) => {
        acc[agentConfig.id] = agentConfig.active;
        return acc;
      },
      {},
    ),
  );

  return (
    <GradientBackdrop>
      <div className="px-6">
        <div className="h-25 flex flex-row gap-x-6 py-3 items-center">
          {/** TODO: move this into the UI package */}
          <img
            src={trunkToolsWhite}
            alt="trunk tools logo"
            className="w-13 h-8"
          />
          <p className="uppercase text-sm">agent manager</p>
        </div>
        <div className="flex flex-row gap-x-4 text-xl mb-8">
          <p
            className="underline cursor-pointer"
            onClick={() => {
              idNavigate("/conversations/new");
            }}
          >
            Home
          </p>
          <p>&gt;</p>
          <p>Manage Agents</p>
        </div>
        <FeatureFlagGate
          enabled={() => (
            <div>
              <Button
                isInline
                variant="success"
                onClick={() => {
                  idNavigate("/agents/new");
                }}
              >
                <img
                  src={plusWhite}
                  alt="new conversation plus"
                  className="w-4 h-4"
                />
                Create New Agent
              </Button>
            </div>
          )}
          disabled={() => null}
          flagKey={(FlagKey) => FlagKey.QuestionBankAgent}
        />
        <div className="flex flex-col mt-6 gap-y-6 font-poppins">
          {agentConfigs.agent_configs.map((agentConfig) => {
            return (
              <AgentRow
                key={agentConfig.id}
                name={agentConfig.name}
                cron_descriptor={toCronDescriptor(
                  agentConfig.cron_expression,
                  agentConfigs.server_timezone,
                )}
                active={agentConfigsActive[agentConfig.id]}
                onChangeSwitch={() => {
                  updateUserProjectAgentConfig({
                    agent_config_id: agentConfig.id,
                    active: !agentConfigsActive[agentConfig.id],
                  });
                  setAgentConfigsActive({
                    ...agentConfigsActive,
                    [agentConfig.id]: !agentConfigsActive[agentConfig.id],
                  });
                  refetchAgentConfigs();
                }}
                onRun={() => {
                  runAgent({ agent_config_id: agentConfig.id });
                }}
                onEdit={
                  agentConfig.kind !== "schedule"
                    ? () => {
                        idNavigate(`/agents/${agentConfig.id}`);
                      }
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    </GradientBackdrop>
  );
};
