import { ConversationScheduleAgentOverviewItem } from "@trunk-tools/txt-shared";
import { ConversationItemMessage, Button } from "@trunk-tools/ui";
import tWhite from "public/T_white.svg";

type Props = {
  item: ConversationScheduleAgentOverviewItem;
  updateQuestionText: (text: string) => void;
  isLastItem: boolean;
};

export const ScheduleAgentSuggestedFollowupsItem = ({
  item,
  updateQuestionText,
  isLastItem,
}: Props) => {
  const { suggested_followup_questions } = item;

  const headerContent = (
    <div className="flex flex-col gap-y-2">
      <div>
        {item.created_at.toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </div>
      <div className="flex flex-row items-center text-lg gap-x-1 font-poppins">
        <p className="font-bold">Some questions I can answer</p>
      </div>
    </div>
  );

  return (
    <ConversationItemMessage
      variant="green"
      withoutAvatar={true}
      collapsible
      highlightOnHoverWhileOpen
      headerContent={headerContent}
      collapsedHeaderContent={headerContent}
      partOfPreviousItem
      isLastItem={isLastItem}
    >
      <div className="flex flex-col gap-y-4">
        {suggested_followup_questions.map((question) => {
          return (
            // Using a random key here requires double click on mobile
            <div key={question}>
              <Button
                variant="newVariant"
                onClick={() => updateQuestionText(question)}
                isInline
              >
                <img src={tWhite} alt="trunktext logo" className="w-4 h-4" />
                <span className="text-wrap">{question}</span>
              </Button>
            </div>
          );
        })}
      </div>
    </ConversationItemMessage>
  );
};
