import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SlimWithDesktopImage,
  SubmittableText,
  ViewRenderer,
  Pin,
  Form,
  AnimateIn,
} from "@trunk-tools/ui";
import { useState, useEffect } from "react";
import constructionImg from "public/construction.jpg";
import txtLogo from "public/TT_white.svg";
import { useLogin } from "dataHooks";
import { api } from "@/dataHooks/helpers/APIClient";
import { mutate } from "swr";
import {
  ConsentType,
  getLatestConsentLink,
  getLatestTosLink,
} from "@trunk-tools/txt-shared";

type PhoneNumberViewProps = {
  setView: (nextView: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
};

const PhoneNumberView = ({ setView, setPhoneNumber }: PhoneNumberViewProps) => {
  const { generateLoginCode } = useLogin();
  const onSubmit = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    generateLoginCode({ phone: `+1${phoneNumber}` });
    setView("code");
  };

  return (
    <>
      <div className="mb-5">
        Enter your mobile number and we’ll text you a verification code.
      </div>
      <SubmittableText
        placeholder="XXX-XXX-XXXX"
        mask="999-999-9999"
        buttonText="Send Code"
        onSubmit={onSubmit}
        validate={Form.validators.phoneNumber()}
      />
    </>
  );
};

type CodeViewProps = {
  phoneNumber: string;
  setView: (nextView: string) => void;
};

const CodeView = ({ phoneNumber, setView }: CodeViewProps) => {
  const [hasResentCode, setHasResentCode] = useState<number | false>(false);
  const { login, generateLoginCode } = useLogin();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  const onComplete = async (code: string) => {
    const result = await login({ phone: `+1${phoneNumber}`, code });
    // throw any error so that the UI component know the request "failed"
    // this is different from our API schema which does not "throw" for 401s
    // so just manually convert an incorrect login to an error here
    if (!result.user) {
      throw new Error("Login failed");
    }

    let urlToNavigateTo = "/";

    const { businesses } = await api.userBusinesses({});
    const me = await api.me({});

    mutate("userBusinesses", businesses, false);
    mutate("me", me, false);

    if (redirect) {
      urlToNavigateTo = redirect;
    } else if (me.user?.last_project_id) {
      urlToNavigateTo = `/projects/${me.user.last_project_id}/conversations`;
    } else if (businesses.length === 1 && businesses[0].projects.length === 1) {
      urlToNavigateTo = `/projects/${businesses[0].projects[0].id}/conversations`;
    }

    navigate(urlToNavigateTo);
  };

  const resendCode = () => {
    generateLoginCode({ phone: `+1${phoneNumber}` });
    setHasResentCode(30);
  };

  useEffect(() => {
    if (typeof hasResentCode !== "number") return;

    if (hasResentCode === 0) return setHasResentCode(false);

    const timer = setTimeout(() => {
      setHasResentCode(hasResentCode - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [hasResentCode]);

  return (
    <>
      <div className="mb-5">
        Enter the verification code we sent to +1-XXX-XXX-XX
        {phoneNumber.slice(-2)}
      </div>
      <Pin onComplete={onComplete} length={6} />
      <div>
        {hasResentCode === false && (
          <div className="mt-6">
            <span
              onClick={resendCode}
              className="text-blue-600 text-xs mt-6 hover:underline cursor-pointer"
            >
              RESEND CODE
            </span>
          </div>
        )}
        {typeof hasResentCode === "number" && (
          <div className="text-green-100 text-xs mt-6">
            <div className="text-sm">Code resent</div>
            <div className="text-xs">
              Please wait {hasResentCode} seconds before trying again
            </div>
          </div>
        )}
        <span
          onClick={() => setView("phone")}
          className="text-blue-600 text-xs mt-2 hover:underline cursor-pointer"
        >
          GO BACK
        </span>
      </div>
    </>
  );
};

export const LoginPage = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  return (
    <SlimWithDesktopImage desktopImageUrl={constructionImg}>
      <div className="flex flex-col h-full">
        <div className="mt-auto">
          <AnimateIn>
            <img
              src={txtLogo}
              alt="TrunkText logo"
              className="w-[220px] mb-4"
            />
            <ViewRenderer
              initialView={"phone"}
              views={{
                phone: PhoneNumberView,
                code: CodeView,
              }}
              injectedProps={{ phoneNumber, setPhoneNumber }}
            />
          </AnimateIn>
        </div>
        <div className="flex flex-col text-center mt-auto">
          <div>
            For support, contact{" "}
            <a
              className="underline text-brand"
              href="mailto:support@trunk.tools"
            >
              support@trunk.tools
            </a>{" "}
            or call us at (650) 471-9687
          </div>
          <div>
            <a
              className="underline text-brand"
              target="_blank"
              href={getLatestConsentLink(ConsentType.PrivacyPolicy)}
            >
              Privacy Policy
            </a>
            {" | "}
            <a
              className="underline text-brand"
              target="_blank"
              href={getLatestTosLink()}
            >
              Terms of Service
            </a>
          </div>
        </div>
      </div>
    </SlimWithDesktopImage>
  );
};
