import dayjs from "dayjs";
import { HeaderWithValue, Progress, Spinner } from "@trunk-tools/ui";

interface Props {
  displayName: string;
  documentStatusInfo: {
    processed: number;
    processing: number;
    total: number;
    last_updated: Date | null;
  };
}

export const SyncInfoStatus = ({ displayName, documentStatusInfo }: Props) => {
  const processedRatio =
    documentStatusInfo.processed / documentStatusInfo.total;

  return (
    <>
      <HeaderWithValue header="Source" value={displayName} />
      <HeaderWithValue
        header="Status"
        value={
          processedRatio >= 1 || documentStatusInfo.total === 0 ? (
            "Up to Date"
          ) : (
            <div className="flex">
              <div className="mr-3">Processing...</div>
              <Spinner />
            </div>
          )
        }
      />
      {documentStatusInfo.processing > 0 && (
        <>
          <HeaderWithValue
            header="Progress"
            value={`${documentStatusInfo.processed} out of ${documentStatusInfo.total} documents processed`}
          />
          <div className="mb-6 -mt-4">
            <Progress percent={processedRatio * 100} />
          </div>
        </>
      )}

      <HeaderWithValue
        header="Total Synced Files"
        value={documentStatusInfo.total}
      />

      <HeaderWithValue
        header="Last Updated"
        value={
          documentStatusInfo.last_updated
            ? dayjs(documentStatusInfo.last_updated).format("MM/DD/YYYY")
            : "-"
        }
      />
    </>
  );
};
