import { buildTTApiClient, Specs } from "@trunk-tools/txt-shared";
import { toast } from "@trunk-tools/ui";
import { z } from "zod";

const apiClient = buildTTApiClient();

type ApiClientType = typeof apiClient;

type ApiMethodNameType = keyof ApiClientType;

type ApiSuccessfulResponseType<M extends ApiMethodNameType> = z.infer<
  Specs[M]["response"]
>;

type ApiMethodArgsType<M extends ApiMethodNameType> = Parameters<
  ApiClientType[M]
>;

type ApiReturnType = {
  [K in ApiMethodNameType]: (
    ...args: ApiMethodArgsType<K>
  ) => Promise<ApiSuccessfulResponseType<K>>;
};

const errorAllowed = ["getBusinesses", "userBusinesses"];

export const api = Object.entries(apiClient).reduce((acc, [key, method]) => {
  const typedKey = key as ApiMethodNameType;

  return {
    ...acc,
    [typedKey]: async (...args: ApiMethodArgsType<typeof typedKey>) => {
      // @ts-expect-error turple types or something
      const result = await method(...args);

      if (result.success === false) {
        if (result.error === undefined) {
          toast.error("An unexpected error has occurred");
        } else if (result.error.includes("invalid_type")) {
          if (process.env.DEVELOPMENT) {
            console.error(result.error);
            console.error("The args for above are ", args);
            toast.error("Zod type mismatch error, see console for details");
          } else {
            toast.error("An unexpected error has occurred");
          }
        } else if (result.error.includes("MISSING_CONSENT")) {
          if (!window.location.href.includes("/consent"))
            window.location.href = "/consent";
        } else if (errorAllowed.includes(key)) {
          // throw and let the caller handle it
          throw new Error(result.error);
        } else {
          toast.error(result.error || "An unexpected error has occurred");
        }

        return;
      }

      const successfulResponse = result.data as ApiSuccessfulResponseType<
        typeof typedKey
      >;

      return successfulResponse;
    },
  };
}, {} as ApiReturnType);
