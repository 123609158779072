import { useLocation } from "react-router-dom";
import {
  useMaybeCurrentBusiness,
  useMaybeCurrentProject,
  useMe,
} from "dataHooks";
import { useEffect } from "react";
import { usePendo } from "@/dataHooks/global.dataHook";
import { SuspenseErrorBoundary } from "./SuspenseErrorBoundary.layout";

// new layout mostly defines shared helpers, but the
// new pages don't share enough UI to warrant putting
// them in a shared layout
const LoggedInLayout: ReactComponent = ({ children }) => {
  const { pathname } = useLocation();
  const { me } = useMe();
  const { currentProject } = useMaybeCurrentProject();
  const { currentBusiness } = useMaybeCurrentBusiness();

  const pendo = usePendo();
  useEffect(() => {
    pendo(me, currentBusiness, currentProject);
  }, [currentBusiness?.id, currentProject?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="h-svh">
      <SuspenseErrorBoundary>{children}</SuspenseErrorBoundary>
    </div>
  );
};

export { LoggedInLayout };
