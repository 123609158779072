import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { api } from "@/dataHooks/helpers/APIClient";
import { useEffect, useState } from "react";
import { useAgaveLink } from "@agave-api/react-agave-link";
import { useMe, useCurrentProject } from "dataHooks";
import {
  AgaveConfigMatrix,
  AgaveSourceSystemsType,
} from "@trunk-tools/txt-shared";
import { useInterval } from "@/hooks/useInterval";
import { Stack, toast } from "@trunk-tools/ui";

export const useAgaveAccountSetup = () => {
  // STEP 1: We get the link token a button is pushed in the UI
  const { data: linkToken, trigger: getAgaveLinkToken } = useSWRMutation(
    "getUACLinkToken",
    () => api.getUACLinkToken({}),
  );

  const [hasExchangedToken, setHasExchangedToken] = useState(false);
  const [agaveLinkIsOpen, setAgaveLinkIsOpen] = useState(false);
  const [uacAccount, setUacAccount] = useState<
    Awaited<ReturnType<typeof api.uacPublicTokenExchange>> | undefined
  >(undefined);
  const [uacAccountStatus, setUacAccountStatus] = useState<
    "Ready" | "Initializing" | "Failed" | undefined
  >();
  const [projectSearchFilter, setProjectSearchFilter] = useState("");

  const [uacPotentialProjectsResult, setUacPotentialProjectsResult] = useState<
    Awaited<ReturnType<typeof api.getUACPotentialProjects>> | undefined
  >(undefined);
  const uacPotentialProjects =
    uacPotentialProjectsResult?.potential_projects || [];
  const uacSourceSystem =
    uacPotentialProjectsResult?.source_system as AgaveSourceSystemsType;

  const [potentialProjectsSearch, setPotentialProjectsSearch] = useState("");
  const [isLoadingPotentialProjects, setIsLoadingPotentialProjects] =
    useState(false);

  useInterval(
    async () => {
      if (uacAccount) {
        const result = await api.getUACAccountStatus({
          uac_account_id: uacAccount?.id || "",
        });
        console.log("account status result", result);
        setUacAccountStatus(result.lifecycle_status);
        if (result.lifecycle_status === "Ready") {
          setIsLoadingPotentialProjects(true);
          const projects = await api.getUACPotentialProjects({
            accountId: uacAccount.id,
          });
          setUacPotentialProjectsResult(projects);
          setIsLoadingPotentialProjects(false);
        } else if (result.lifecycle_status === "Failed") {
          console.error("Account setup failed");
          toast.error(
            <Stack gap={2}>
              <div>Failed to connect integration</div>
              <div className="text-sm">
                Please contact support for assistance.
              </div>
            </Stack>,
          );
        }
      }
    },
    uacAccount && (!uacAccountStatus || uacAccountStatus === "Initializing")
      ? 2000
      : null,
  );

  useEffect(() => {
    if (!uacAccount) return;
    const fetchPotentialProjects = async (searchStr: string) => {
      setIsLoadingPotentialProjects(true);
      const projects = await api.getUACPotentialProjects({
        accountId: uacAccount.id,
        ...(potentialProjectsSearch ? { search: searchStr } : {}),
      });
      setUacPotentialProjectsResult(projects);
      setIsLoadingPotentialProjects(false);
    };
    fetchPotentialProjects(potentialProjectsSearch);
  }, [potentialProjectsSearch]);

  const [publicToken, setPublicToken] = useState("");

  const { me } = useMe();

  // STEP 2 - we call useAgaveLink with the correct link token
  const { openLink, isReady: agaveIsReady } = useAgaveLink({
    referenceId: me.id,

    // Replace linkToken with what you got in step 2 on https://docs.agaveapi.com/quickstart#step-2-generate-link-token
    linkToken: linkToken?.token || "",
    theme: "dark",
    showSandboxSourceSystems: true, // Only for local development
    showProductionSourceSystems: true,
    sourceSystem: ["procore", "sharepoint-online", "autodesk-build"],
    // sourceSystemEnvironment: "sandbox",

    // STEP 4: Agave iframe is complete, we get the token and set it to state
    onSuccess: (publicToken: string) => {
      setPublicToken(publicToken);
    },
    onExit: () => {
      setAgaveLinkIsOpen(false);
    },
  });

  // STEP 3: Open the Agave iframe when it's ready
  useEffect(() => {
    if (agaveIsReady) {
      openLink();
      setAgaveLinkIsOpen(true);
    }
  }, [linkToken, agaveIsReady]);

  // STEP 5: Exchange token
  useEffect(() => {
    const exchangePublicToken = async () => {
      const uacAccountResult = await api.uacPublicTokenExchange({
        public_token: publicToken,
      });

      setUacAccount(uacAccountResult);
      setHasExchangedToken(true);
    };

    if (publicToken && !hasExchangedToken) {
      exchangePublicToken();
    }
  }, [publicToken, hasExchangedToken, projectSearchFilter]);

  useEffect(() => {
    const search = async () => {
      if (hasExchangedToken && uacAccount && projectSearchFilter) {
        setIsLoadingPotentialProjects(true);
        setUacAccountStatus("Initializing");
        await api.uACProjectSearch({
          account_id: uacAccount.id,
          search_term: projectSearchFilter,
        });
        setIsLoadingPotentialProjects(false);
      }
    };
    search();
  }, [hasExchangedToken, uacAccount, projectSearchFilter]);

  return {
    getAgaveLinkToken,
    openLink,
    agaveIsReady,
    uacPotentialProjectsResult,
    linkToken,
    agaveLinkIsOpen,
    uacPotentialProjects,
    uacSourceSystem,
    searchPotentialProjects: setPotentialProjectsSearch,
    isLoadingPotentialProjects,
    uacAccount,
    uacAccountStatus,
    projectSearchFilter,
    setProjectSearchFilter,
  };
};

export const useUACProjectStatus = ({
  uac_project_id,
}: {
  uac_project_id: string;
}) => {
  const { data: uacProjectStatus, mutate: refetchUACProjectStatus } = useSWR(
    ["UACProjectStatus", uac_project_id],
    () => api.getUACProjectStatus({ uac_project_id }),
    { suspense: true },
  );

  const hasSyncedFolders = (uacProjectStatus.uac_project_folders || []).some(
    (project) => project.is_implicitly_watched || project.has_watched_children,
  );

  return {
    uacProjectStatus,
    refetchUACProjectStatus,
    hasSyncedFolders,
  };
};

export const useUACAccountStatus = ({
  uac_account_id,
}: {
  uac_account_id: string;
}) => {
  const { data: uacProjectStatus, mutate: refetchUACProjectStatus } = useSWR(
    ["UACAccountStatus", uac_account_id],
    () => api.getUACAccountStatus({ uac_account_id }),
    { suspense: true },
  );

  return {
    uacProjectStatus,
    refetchUACProjectStatus,
  };
};

export const useUACProject = ({
  uac_project_id,
}: {
  uac_project_id: string;
}) => {
  const { hasSyncedFolders, refetchUACProjectStatus, uacProjectStatus } =
    useUACProjectStatus({ uac_project_id });

  const { data: uacProjectSyncConfig } = useSWR(
    ["uacProjectSyncConfig", uac_project_id],
    () =>
      api.getUACProjectSyncConfig({
        uac_project_id: uac_project_id,
      }),
    { suspense: true },
  );

  const syncConfig = uacProjectSyncConfig.sync_config;
  const watchedFolderIds = uacProjectSyncConfig.watched_folder_ids;
  const nothingIsSynced =
    !syncConfig ||
    (!syncConfig.rfis && !syncConfig.submittals && !hasSyncedFolders);

  const { trigger: updateUACProjectSyncConfig } = useSWRMutation<
    unknown,
    unknown,
    undefined | [string, string],
    {
      rfis?: boolean;
      submittals?: boolean;
      specs?: boolean;
      drawings?: boolean;
      folders?: string[];
    }
  >(["updateUACProjectSync", uac_project_id], async (_, { arg }) => {
    await api.updateUACProjectSyncConfig({
      uac_project_id,
      rfis: typeof arg.rfis === "boolean" ? arg.rfis : syncConfig.rfis || false,
      submittals:
        typeof arg.submittals === "boolean"
          ? arg.submittals
          : syncConfig.submittals || false,
      specs:
        typeof arg.specs === "boolean" ? arg.specs : syncConfig.specs || false,
      drawings:
        typeof arg.drawings === "boolean"
          ? arg.drawings
          : syncConfig.drawings || false,
      folders: arg.folders || [],
    });
    refetchUACProjectStatus();
  });

  const configMatrix =
    AgaveConfigMatrix[
      uacProjectStatus.uac_project.uac_account
        .source_system as AgaveSourceSystemsType
    ];

  return {
    updateUACProjectSyncConfig,
    refetchUACProjectStatus,
    uacProjectStatus,
    uacProjectSyncConfig: syncConfig,
    nothingIsSynced,
    watchedFolderIds,
    uacFeatureSupport: configMatrix?.featureSupport,
    uacDisplayName: configMatrix?.displayName,
  };
};

export const useUACProjects = () => {
  const { currentProject } = useCurrentProject();

  const { data: uacProjects } = useSWR(
    ["uacProjects", currentProject.id],
    () =>
      api.getUACProjects({
        project_id: currentProject.id,
      }),
    { suspense: true },
  );

  return {
    uacProjects,
  };
};
