import { TouchEvent, useState } from "react";

interface SwipeInput {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
}

export const useSwipe = (input: SwipeInput): SwipeOutput => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const minSwipeDistance = 250;

  const onTouchStart = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent) =>
    setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = (e: TouchEvent) => {
    // prevent all defaults and propagation so that we can manually trigger a click later if need be
    e.preventDefault();
    e.stopPropagation();

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      input.onSwipedLeft();
    } else if (isRightSwipe) {
      input.onSwipedRight();
    } else {
      // if it wasn't swipe, re-trigger the possible actions
      if ("click" in e.target && typeof e.target.click === "function") {
        e.target.click();
      }
      if ("focus" in e.target && typeof e.target.focus === "function") {
        e.target.focus();
      }
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
