import { api } from "./helpers/APIClient";
import {
  LoginBodyType,
  AgreeToConsentsBodyType,
} from "@trunk-tools/txt-shared";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export const useLogin = () => {
  return {
    generateLoginCode: api.generateLoginCode,
    login: async (args: LoginBodyType) => {
      const result = await api.login(args);
      // refetch user
      mutate("me");
      mutate("isLoggedIn");
      return result;
    },
    logout: async () => {
      await api.logout({});
      mutate("me");
      mutate("isLoggedIn");
    },
  };
};

export const useIsLoggedIn = () => {
  const fetch = async () => {
    try {
      const { user } = await api.me({});
      return user;
    } catch (e) {
      return null;
    }
  };

  const { data: maybeUser, mutate: refetchIsLoggedIn } = useSWR(
    "isLoggedIn",
    fetch,
    {
      suspense: true,
    },
  );

  return { isLoggedIn: !!maybeUser, refetchIsLoggedIn };
};

export const useMe = () => {
  const { refetchIsLoggedIn } = useIsLoggedIn();

  const fetch = async () => {
    const me = await api.me({});
    refetchIsLoggedIn();
    return me;
  };

  const { data, mutate } = useSWR("me", fetch, { suspense: true });

  // only use this for logged in routes
  const me = data.user as NonNullable<(typeof data)["user"]>;
  const meInitials = me.first_name?.[0] + me.last_name?.[0];

  const changeProject = async (projectId: string) => {
    await api.userUpdateSelf({ last_project_id: projectId });
    mutate();
    refetchIsLoggedIn();
  };

  return {
    me,
    meInitials,
    refetchMe: mutate,
    changeProject,
  };
};

export const useConsents = () => {
  const { data, mutate: refetchConsents } = useSWR(
    "consents",
    () => api.getConsents({}),
    {
      suspense: true,
    },
  );

  const { trigger: agreeToConsents } = useSWRMutation<
    unknown,
    unknown,
    string,
    AgreeToConsentsBodyType
  >("agreeToConsents", async (_, { arg }) => {
    await api.agreeToConsents(arg);
    refetchConsents();
  });

  return { consents: data.consents, agreeToConsents };
};
