import { ConversationItemMessage } from "@trunk-tools/ui";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";

type AnsweredQuestionConversationItemProps = {
  children: React.ReactNode;
};

export const AnsweredQuestionConversationItem = ({
  children,
}: AnsweredQuestionConversationItemProps) => {
  return (
    <ConversationItemMessage
      variant="brand"
      avatarProps={{
        variant: "v2yellowResponsive",
        img: ttMessageAvatarLogo,
        name: "TrunkText",
      }}
      isLastItem
      collapsible
      highlightOnHoverWhileClosed
      hideHeader
      headerContent={<p className="uppercase">response</p>}
      collapsedHeaderContent={<p className="uppercase">view response</p>}
    >
      {children}
    </ConversationItemMessage>
  );
};
