import { ConversationItemMessage } from "@trunk-tools/ui";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";

type AgentAnsweredQuestionConversationItemProps = {
  children: React.ReactNode;
  partOfPreviousItem?: boolean;
  isLastItem?: boolean;
  collapsible?: boolean;
  collapsedHeaderContent?: React.ReactNode;
  onCollapse?: () => void;
};

export const AgentAnsweredQuestionConversationItem = ({
  children,
  partOfPreviousItem,
  isLastItem,
  collapsible,
  collapsedHeaderContent,
  onCollapse,
}: AgentAnsweredQuestionConversationItemProps) => {
  return (
    <ConversationItemMessage
      variant="green"
      withoutAvatar={partOfPreviousItem}
      avatarProps={
        !partOfPreviousItem
          ? {
              variant: "v2yellowResponsive",
              img: ttMessageAvatarLogo,
              name: "TrunkText",
            }
          : undefined
      }
      collapsible={collapsible}
      onCollapse={onCollapse}
      collapsedHeaderContent={collapsedHeaderContent}
      headerContent={collapsedHeaderContent}
      partOfPreviousItem={partOfPreviousItem}
      isLastItem={isLastItem}
      hideHeader
    >
      {children}
    </ConversationItemMessage>
  );
};
