import { ConversationItemMessage } from "@trunk-tools/ui";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";

type AnswerErrorConversationItemProps = {
  children: React.ReactNode;
};

export const AnswerErrorConversationItem = ({
  children,
}: AnswerErrorConversationItemProps) => {
  return (
    <ConversationItemMessage
      variant="error"
      avatarProps={{
        variant: "v2redResponsive",
        img: ttMessageAvatarLogo,
        name: "TrunkText",
      }}
      hideHeader
    >
      {children}
    </ConversationItemMessage>
  );
};
