import { Avatar, DropdownMenu, Select } from "@trunk-tools/ui";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useMaybeCurrentBusiness,
  useMaybeCurrentProject,
  useMe,
  useUserProjects,
} from "dataHooks";
import txtLogo from "public/TT_white.svg";
import { SuspenseErrorBoundary } from "./SuspenseErrorBoundary.layout";
import { useCanDo } from "@/hooks/useCanDo";
import { useEffect } from "react";
import { ProjectPermission } from "@trunk-tools/txt-shared";
import { ConstrainWidth } from "@trunk-tools/ui";
import { useMaybeBusinessProjectId } from "@/hooks/userUrlParams";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { usePendo } from "@/dataHooks/global.dataHook";

type Props = {
  children?: React.ReactNode;
};

export const LoggedInLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const idNavigate = useIdNavigate();
  const { pathname } = useLocation();
  const { me, changeProject } = useMe();
  const { currentProject } = useMaybeCurrentProject();
  const { currentBusiness } = useMaybeCurrentBusiness();
  const { projectId } = useMaybeBusinessProjectId();
  const { projects } = useUserProjects();
  const projectCount = projects.length;

  const pendo = usePendo();
  useEffect(() => {
    pendo(me, currentBusiness, currentProject);
  }, [currentBusiness?.id, currentProject?.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { can: canManageDocs } = useCanDo({
    permission: ProjectPermission.ManageDocuments,
  });
  const logout = () => {
    navigate("/logout");
  };

  const projURL = (path: string) => `/projects/${projectId}${path}`;
  return (
    <div className="h-svh flex flex-col">
      <ConstrainWidth>
        <div className="px-4 py-3 flex justify-between">
          <img
            src={txtLogo}
            alt="txt logo"
            className="h-7 mt-2 cursor-pointer"
            onClick={() => navigate("/")}
          />
          <DropdownMenu
            items={[
              {
                type: "component" as const,
                component: (
                  <div className="pb-4">
                    <Select
                      name="something"
                      placeholder="Select something..."
                      value={projectId}
                      items={projects.map((p) => ({
                        value: p.id,
                        label: p.name,
                      }))}
                      onChange={async (id: string) => {
                        const pathParts = pathname.split("/");
                        const suffix = pathParts
                          .slice(pathParts.indexOf(projectId!) + 1)
                          .join("/");

                        changeProject(id);

                        navigate(`/projects/${id}/${suffix}`);
                      }}
                    />
                  </div>
                ),
                hide: projectCount <= 1,
              },
              {
                type: "link" as const,
                onClick: () => idNavigate("/conversations"),
                label: "Ask a Question",
                hide: pathname === projURL("/conversations") || !projectId,
              },
              {
                type: "link" as const,
                onClick: () => idNavigate("/integrations"),
                label: "Integrations",
                hide:
                  pathname === projURL("/integrations") ||
                  !canManageDocs ||
                  !projectId,
              },
              { type: "link" as const, onClick: logout, label: "Log Out" },
            ]}
          >
            {me && (
              <Avatar initials={`${me.first_name[0]}${me.last_name[0]}`} />
            )}
          </DropdownMenu>
        </div>
      </ConstrainWidth>

      <div className="border-b-2 border-brand" />

      <ConstrainWidth className="h-full">
        <div className="grid grid-cols-12 h-full">
          <div className="hidden desktop:block col-span-3 co bg-brand px-[8px] py-[8px] uppercase text-black">
            <div className="flex flex-row justify-between flex-wrap">
              {currentProject && (
                <div className="text-3xl">{currentProject.name}</div>
              )}
            </div>
          </div>

          <div className="col-span-12 desktop:col-span-9">
            <div className="m-4">
              <SuspenseErrorBoundary>{children}</SuspenseErrorBoundary>
            </div>
          </div>
        </div>
      </ConstrainWidth>
    </div>
  );
};
