import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import {
  protectedRoutes,
  isNotLoggedInRoutes,
  openRoutes,
} from "./routeDefinitions";
import {
  ProtectedRoute,
  IsNotLoggedIn,
  RouterSuspenseErrorTopLevelBoundary,
  LoggedInLayout,
  RewriteBusinesses,
} from "./routerWrappers";
import { IndexPage } from "@/pages/Index.page";

const routes = (
  <Route element={<RouterSuspenseErrorTopLevelBoundary />} key="suspense">
    <Route path="/businesses/:businessId/*" element={<RewriteBusinesses />} />
    <Route element={<ProtectedRoute />} key="protected">
      <Route element={<LoggedInLayout />} key="loggedIn">
        {protectedRoutes}
      </Route>
    </Route>
    <Route element={<IsNotLoggedIn />} key="noLoggedIn">
      {isNotLoggedInRoutes}
    </Route>
    <Route key="open">{openRoutes}</Route>
    <Route path="/" element={<IndexPage />} />
    <Route path="*" element={<Navigate to="/login" replace />} key="catchall" />
  </Route>
);
const router = createBrowserRouter(createRoutesFromElements(routes));

export { router };
