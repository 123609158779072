import { AnimateTransition, ThinkingAnimation } from "@trunk-tools/ui";

export const ThinkingMessageContent = ({
  id,
  text,
}: {
  id: string;
  text: string;
}) => {
  return (
    <div className="flex flex-col gap-y-5">
      <div className="uppercase text-white opacity-50">{text}</div>
      <AnimateTransition id={id} justOpacity={true} initial={true}>
        <ThinkingAnimation />
      </AnimateTransition>
    </div>
  );
};
