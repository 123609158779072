import { Skeleton, ErrorDeadEnd, DelayRender } from "@trunk-tools/ui";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

// add this anywhere you'd like to see a loading skeleton
// when an inner hook is fetched, and an error message
// when an error is thrown

type SuspenseErrorBoundaryProps = {
  size?: "component" | "screen";
  children: React.ReactNode;
};

export const SuspenseErrorBoundary = ({
  size = "component",
  children,
}: SuspenseErrorBoundaryProps) => (
  <ErrorBoundary fallback={<ErrorDeadEnd />}>
    <Suspense
      fallback={
        // delay showing the full screen skeleton for a bit
        // to avoid it flashing on the screen for a split second
        <DelayRender delay={size === "component" ? 0.4 : 0}>
          <div className="p-4 flex justify-center">
            <div className="grow max-w-[900px]">
              <Skeleton size={size === "component" ? "md" : "lg"} />
            </div>
          </div>
        </DelayRender>
      }
    >
      {children}
    </Suspense>
  </ErrorBoundary>
);
