import {
  GlobalPermissionUnion,
  ProjectPermissionUnion,
  GlobalPermission,
  ProjectPermission,
  canDo,
} from "@trunk-tools/txt-shared";
import { useMe } from "dataHooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBusinessProjectId } from "./userUrlParams";

export function useCanDo({
  permission,
}: {
  permission: ProjectPermissionUnion | GlobalPermissionUnion;
}) {
  const { businessId, projectId } = useBusinessProjectId();
  const project = {
    id: projectId,
    business: { id: businessId },
  };

  const { me: user } = useMe();

  if (
    Object.values(ProjectPermission).includes(
      permission as ProjectPermissionUnion,
    )
  ) {
    const typedPermission = permission as ProjectPermissionUnion;
    const can = canDo({ user, permission: typedPermission, project });
    return { can };
  }

  if (
    Object.values(GlobalPermission).includes(
      permission as GlobalPermissionUnion,
    )
  ) {
    const typedPermission = permission as GlobalPermissionUnion;
    const can = canDo({ user, permission: typedPermission });
    return { can };
  }

  return { can: false };
}

export function useCanDoOrRedirect({
  permission,
  redirect = "/",
}: {
  permission: ProjectPermissionUnion | GlobalPermissionUnion;
  redirect?: string;
}) {
  const { can } = useCanDo({ permission });
  const navigate = useNavigate();
  useEffect(() => {
    if (can !== null && !can) {
      navigate(redirect);
    }
  }, [can]);

  return { can };
}
