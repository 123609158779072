import { Form, Text } from "@trunk-tools/ui";

export const UacProjectSearchFilter = ({
  setProjectSearchFilter,
}: {
  setProjectSearchFilter: (search: string) => void;
}) => {
  return (
    <Form.Provider
      initialValues={{ search: "" }}
      onSubmit={async (values) => {
        setProjectSearchFilter(values.search);
      }}
    >
      <>
        <Text.Header className="mb-3">Enter Projects To Search For</Text.Header>
        <div className="mb-5 w-99">
          <Form.Fields.Text name="search" />
        </div>
      </>
    </Form.Provider>
  );
};
