import { useEffect } from "react";
import { Form, TextLink, Stack } from "@trunk-tools/ui";
import { SuspenseErrorBoundary } from "@/layouts/SuspenseErrorBoundary.layout";
import {
  getLatestConsentLink,
  ConsentType,
  ConsentDisplayNames,
  LatestConsentVersions,
  AgreeToConsentsBodyType,
} from "@trunk-tools/txt-shared";
import { useConsents } from "dataHooks";

const getConsentValidators = (passed?: ConsentType[]) => {
  const show = passed || Object.values(ConsentType);

  return show.reduce(
    (acc, consentType) => ({
      ...acc,
      [consentType]: Form.validators.onlyTrue,
    }),
    {},
  );
};

export const ConsentCheckboxes = (props: { show?: ConsentType[] } = {}) => {
  const { show = Object.values(ConsentType) } = props;

  return (
    <Stack gap={3}>
      {show.map((consent) => (
        <Form.Fields.Checkbox
          key={consent}
          name={consent}
          label={
            <div>
              I have read and agree to the{" "}
              <TextLink
                href={getLatestConsentLink(consent as ConsentType)}
                isExternal
              >
                {ConsentDisplayNames[consent]}
              </TextLink>
            </div>
          }
        />
      ))}
    </Stack>
  );
};

function ConsentPageInner() {
  const {
    consents: { missing },
    agreeToConsents,
  } = useConsents();

  const onSubmit = async (data) => {
    const consents = Object.entries(data).map(([type, agreed]) => ({
      type,
      agreed,
      version: LatestConsentVersions[type],
    })) as AgreeToConsentsBodyType["consents"];

    await agreeToConsents({ consents });
  };

  useEffect(() => {
    if (Array.isArray(missing) && missing.length === 0) {
      // do a hard reload to clear
      // any previous failed data fetches
      window.location.href = "/";
    }
  }, [missing]);

  if (missing.length === 0) {
    return null;
  }

  const show = missing.map((consent) => consent.type);

  return (
    <div className="mt-4">
      <h1 className="text-2xl mb-2">Updated Agreements</h1>
      <div className="mb-5 italic">
        Please click the links below and read them carefully. By checking the
        boxes, you acknowledge that you have read and agree to our current
        terms.
      </div>
      <Form.Provider
        initialValues={{}}
        onSubmit={onSubmit}
        validators={getConsentValidators(show)}
      >
        <ConsentCheckboxes show={show} />
      </Form.Provider>
    </div>
  );
}

export const ConsentPage = () => (
  <SuspenseErrorBoundary>
    <ConsentPageInner />
  </SuspenseErrorBoundary>
);
