import { ConversationItemMessage } from "@trunk-tools/ui";
import ttMessageAvatarLogo from "public/tt_message_avatar_logo.svg";
import { ThinkingMessageContent } from "../Thinking.component";

export const QuestionThinkingConversationItem = ({
  embeddedQuestion,
  index,
}: {
  embeddedQuestion?: string;
  index: number;
}) => {
  const header = embeddedQuestion ? (
    <div className="h-[86px] p-4 bg-[#364651]/30 rounded-lg border border-[#12191d] flex-col justify-start items-start gap-4 inline-flex w-full mb-4">
      <div className="self-stretch text-white text-[10px] font-normal font-['Izoard Soft'] leading-tight">
        QUESTION {`${index + 1}`}
      </div>
      <div className="self-stretch text-white text-xs font-normal font-['Poppins'] leading-[18px]">
        {embeddedQuestion}
      </div>
    </div>
  ) : (
    <p className="uppercase">response</p>
  );

  return (
    <ConversationItemMessage
      variant="brand"
      avatarProps={{
        variant: "v2yellowResponsive",
        img: ttMessageAvatarLogo,
        name: "TrunkText",
      }}
      collapsible
      hideHeader
      headerContent={header}
      collapsedHeaderContent={<p className="uppercase">view response</p>}
      isLastItem
    >
      <ThinkingMessageContent
        id="generating-answer-thinking"
        text="generating answer..."
      />
    </ConversationItemMessage>
  );
};
