import { useLogin, useIsLoggedIn } from "dataHooks";
import { useEffect } from "react";
import { mutate } from "swr";

export const LogoutPage = () => {
  const { logout } = useLogin();
  const { isLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      mutate(() => true, undefined, { revalidate: false });
      localStorage.clear();
      logout();
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      // hard reload
      window.location.href = "/login";
    }
  }, [isLoggedIn]);

  return null;
};
