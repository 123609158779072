import { useCanDoOrRedirect } from "@/hooks/useCanDo";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { ProjectPermission } from "@trunk-tools/txt-shared";
import { Button } from "@trunk-tools/ui";
import { useProjectDocumentsStatus, useUACProjects } from "dataHooks";
import { BsClipboard } from "react-icons/bs";
import { SyncInfoStatus } from "./SyncInfoStatus.component";
import { LoggedInLayout as OldLoggedInLayout } from "@/layouts/LoggedIn.layout.OLD";

const Integrations = () => {
  const idNavigate = useIdNavigate();
  const { uacProjects } = useUACProjects();
  const { documents_status } = useProjectDocumentsStatus();

  return (
    <>
      <h1 className="mb-6">Integrations</h1>
      <div className="flex justify-between">
        <SyncInfoStatus
          displayName="All"
          documentStatusInfo={documents_status}
        />
      </div>
      <div className="mb-8">
        {uacProjects.map((uacProject) => (
          <div key={uacProject.id} className="mb-4 last-child:mb-0">
            <Button
              icon={BsClipboard}
              onClick={() => idNavigate(`/integrations/${uacProject.id}`)}
              size="sm"
            >
              {uacProject.uac_account.source_system.replaceAll("_", " ")}:{" "}
              {uacProject.name}
            </Button>
          </div>
        ))}
      </div>

      <div>
        <Button onClick={() => idNavigate("/integrations/new")}>
          Create New
        </Button>
      </div>
    </>
  );
};

export function IntegrationsPage() {
  const { can } = useCanDoOrRedirect({
    permission: ProjectPermission.ManageDocuments,
  });

  if (can) {
    return (
      <OldLoggedInLayout>
        <Integrations />
      </OldLoggedInLayout>
    );
  } else {
    return null;
  }
}
