type Config = {
  sentryDsn: string;
  sentryEnvironment: string;
};

const baseConfig: Pick<Config, "sentryDsn"> = {
  sentryDsn:
    "https://e4e8c028679a294629e377f722a829db@o4504158491049984.ingest.us.sentry.io/4507188631109632",
};

const integrationConfig: Config = {
  ...baseConfig,
  sentryEnvironment: "integration",
};

const stagingConfig: Config = {
  ...baseConfig,
  sentryEnvironment: "staging",
};

const productionConfig: Config = {
  ...baseConfig,
  sentryEnvironment: "production",
};

const demoConfig: Config = {
  ...baseConfig,
  sentryEnvironment: "demo",
};

export const getConfig = () => {
  const hostname = document.location.hostname.toLowerCase();

  if (hostname.includes("integration")) {
    return integrationConfig;
  } else if (hostname.includes("staging")) {
    return stagingConfig;
  } else if (hostname.includes("demo")) {
    return demoConfig;
  } else if (hostname === "trunktext.com" || hostname === "www.trunktext.com") {
    return productionConfig;
  }
};
