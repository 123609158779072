import { createContext, useState, ReactNode, useContext } from "react";
// use this sparingly and not for storing server-side data

// this will re-render all global state consumers on change,
// which is OK as long as we keep this usage to a minimum
// can explore some separate providers later if needed

type GlobalStateType = {
  updatedQuestionIds?: string[];
  isPollingUACStatus?: boolean;
  pendoKey?: string;
};

type GlobalContextType = {
  globalState: GlobalStateType;
  setGlobalState: (nextState: GlobalStateType) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const GlobalContext = createContext<GlobalContextType>({
  globalState: {
    updatedQuestionIds: [],
  } as GlobalStateType,
  setGlobalState: () => {},
});

type ProviderProps = {
  children: ReactNode;
};

function GlobalContextProvider({ children }: ProviderProps) {
  const [globalState, setState] = useState({});

  const setGlobalState = (nextState) => {
    setState({
      ...globalState,
      ...nextState,
    });
  };

  const value = {
    globalState,
    setGlobalState,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

const useGlobalState = () => {
  const result = useContext(GlobalContext);
  return result;
};

const usePendo = () => {
  const ctx = useContext(GlobalContext);
  const pendoKey = ctx.globalState.pendoKey;

  return (user, business, project) => {
    if (location.hostname !== "trunktext.com") {
      return;
    }

    const maybeKey = `${business && business.id}__${project && project.id}`;
    if (pendoKey !== maybeKey) {
      ctx.setGlobalState({ pendoKey: maybeKey });

      const account = {
        id: "no_business_in_context",
        name: "no_business_in_context",
        project_id: "no_project_in_context",
        project_name: "no_project_in_context",
      };

      if (business) {
        account.id = business.id;
        account.name = business.name;
      }

      if (project) {
        account.project_id = project.id;
        account.project_name = project.name;
      }

      const { id, email, first_name, last_name, role, phone } = user;

      window.pendo.initialize({
        visitor: {
          id,
          email,
          full_name: `${first_name} ${last_name}`,
          role,
          phone,
        },
        account,
      });
    }
  };
};

export { useGlobalState, usePendo };
export default GlobalContextProvider;
