import React, { useState, createContext } from "react";

export type RefContext = {
  refs: Record<string, React.Ref<HTMLElement>>;
  setRefs: (key: string, value: React.Ref<HTMLElement>) => void;
};

export const RefContext = createContext<RefContext>({
  refs: {},
  setRefs: () => {},
});

export const RefProvider = ({ children }) => {
  const [refs, setRefs] = useState({});

  const setRefsInner = (key, value) => {
    setRefs((refs) => ({ ...refs, [key]: value }));
  };

  return (
    <RefContext.Provider value={{ refs, setRefs: setRefsInner }}>
      {children}
    </RefContext.Provider>
  );
};
