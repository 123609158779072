import { NavigateOptions, To, useNavigate, useParams } from "react-router-dom";

/**
 * This hook is used to navigate to a page with the projectId
 * already in the url.
 *
 * @param path - Must start with a slash or otherwise attach to the /projects/id path.
 *
 **/
export const useIdNavigate = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  return (to: To | number, options: NavigateOptions = {}) => {
    if (typeof to === "number") {
      navigate(to);
      return;
    }

    const url = `/projects/${projectId}${to}`;
    navigate(url, options);
  };
};
