import { useQuestionDocument } from "dataHooks";
import { useParams } from "react-router-dom";
import { DocumentViewer } from "@trunk-tools/ui";
import { ErrorDeadEnd } from "@trunk-tools/ui";
import { download } from "@/utils/download";
import { useIdNavigate } from "@/hooks/useIdNavigate";

export const QuestionDocumentPage = () => {
  const { questionId, documentId } = useParams();
  const navigate = useIdNavigate();
  const { url, questionDocument, ext } = useQuestionDocument({
    questionId: questionId as string,
    documentId: documentId as string,
  });

  if (!questionDocument) {
    return <ErrorDeadEnd message="Document not found" />;
  }

  if (!url) {
    return <ErrorDeadEnd message="Document not viewable via URL" />;
  }

  const additionalProps =
    ext === "pdf"
      ? {
          goToPage:
            "locator" in questionDocument
              ? Number(questionDocument.locator)
              : undefined,
          sourceText:
            "source_text" in questionDocument
              ? String(questionDocument.source_text)
              : "",
        }
      : {};

  return (
    <DocumentViewer
      type={ext as string}
      isOpen={true}
      onClose={() => navigate(`/questions/${questionId}`)}
      title={questionDocument.document.name}
      url={url}
      onDownloadClick={() => download(url, questionDocument.document.name)}
      {...additionalProps}
    />
  );
};
