import { useEffect, useRef } from "react";

// Credit to: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }

    if (delay === null) return;
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
