import axios from "axios";

export const download = (
  url: string,
  filename: string = "trunktext-download.pdf",
) => {
  return axios
    .get(url, {
      responseType: "blob",
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })
    .then((response) => response.data)
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.setAttribute("style", "display: none");
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
};
