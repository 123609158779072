import { api } from "./helpers/APIClient";
import useSWRMutation from "swr/mutation";
import { UserUsefulnessVoteBodyType } from "@trunk-tools/txt-shared";
import { useMe } from "./auth.dataHook";
import { useBusinessProjectId } from "@/hooks/userUrlParams";

export type UsefulnessVoteProps = {
  itemType: UserUsefulnessVoteBodyType["item_type"];
  itemId: UserUsefulnessVoteBodyType["item_id"];
};

export const useUsefulnessVote = ({
  itemType,
  itemId,
}: UsefulnessVoteProps) => {
  const { projectId } = useBusinessProjectId();
  const { me, refetchMe } = useMe();

  const currentVote = me.usefulness_votes.find((vote) => {
    return vote.item_type === itemType && vote.item_id === itemId;
  });

  const fetch = async (_, { arg: { vote } }) => {
    await api.castUsefulnessVote({
      project_id: projectId,
      item_type: itemType,
      item_id: itemId,
      vote,
    });
    refetchMe();
  };

  const { trigger: vote } = useSWRMutation<
    unknown,
    unknown,
    [string, string, string],
    { vote: string }
  >(["vote", itemType, itemId], fetch);

  const votePositive = () => {
    vote({ vote: "POSITIVE" });
  };

  const voteNegative = () => {
    vote({ vote: "NEGATIVE" });
  };

  return { currentVote, votePositive, voteNegative };
};
