import { useQuestion } from "dataHooks";
import { Navigate, useParams } from "react-router-dom";

const QuestionPageInner = ({ questionId }: { questionId: string }) => {
  const { projectId } = useParams();
  const { question } = useQuestion({ questionId });

  return projectId ? (
    <Navigate
      to={`/projects/${projectId}/conversations/${question.conversation_id}`}
    />
  ) : (
    <Navigate to="/" />
  );
};

export const QuestionPage = () => {
  const { questionId } = useParams();

  return questionId ? (
    <QuestionPageInner questionId={questionId} />
  ) : (
    <Navigate to="/" />
  );
};
