import React from "react";
import ReactDOM from "react-dom/client";
import { router } from "./router/router.jsx";
import { RouterProvider } from "react-router-dom";
import SWRProvider from "../dataHooks/helpers/SWRProvider.tsx";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ToastRenderer, StaticBackdrop } from "@trunk-tools/ui";
import GlobalContextProvider from "@/dataHooks/global.dataHook.tsx";
import "@trunk-tools/ui/ui.css";
import { getConfig } from "./config.ts";

const config = getConfig();

if (config) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // Disabling this until we go to a per env basis
    tracesSampleRate: 0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      // if this is an axios error with a 4xx response code don't send to sentry
      if (
        // @ts-expect-error This is the right shape
        hint.originalException?.response?.status &&
        // @ts-expect-error This is the right shape
        `${hint.originalException?.response?.status}`.startsWith("4")
      ) {
        return null;
      }
      return event;
    },
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GlobalContextProvider>
      <SWRProvider>
        <StaticBackdrop>
          <RouterProvider router={router} />
          <ToastRenderer />
        </StaticBackdrop>
      </SWRProvider>
    </GlobalContextProvider>
  </React.StrictMode>,
);
