import { ConversationItemMessage } from "@trunk-tools/ui";

type UserQuestionConversationItemProps = {
  initials: string;
  name: string;
  children: React.ReactNode;
};

export const UserQuestionConversationItem = ({
  initials,
  name,
  children,
}: UserQuestionConversationItemProps) => {
  return (
    <ConversationItemMessage
      variant="blue"
      avatarProps={{
        variant: "v2blueResponsive",
        initials,
        name,
      }}
      isLastItem
    >
      {children}
    </ConversationItemMessage>
  );
};
