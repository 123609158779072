import { useFeatureFlag } from "@/dataHooks/featureFlag.dataHook";
import { FlagKey } from "@trunk-tools/txt-shared";

type FeatureFlagGateProps = {
  enabled: () => React.ReactNode;
  disabled: () => React.ReactNode;
  flagKey: (arg: typeof FlagKey) => FlagKey;
};

const FeatureFlagGate: ReactComponent<FeatureFlagGateProps> = (props) => {
  const {
    enabled: getEnabled,
    disabled: getDisabled,
    flagKey: getFlagKey,
  } = props;

  const { flagEnabled } = useFeatureFlag(getFlagKey);

  const element = flagEnabled ? getEnabled() : getDisabled();

  return element;
};

export { FeatureFlagGate };
