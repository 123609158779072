import { DirectoryExplorer, Stack, PageSection } from "@trunk-tools/ui";
import { useUACProjectStatus } from "dataHooks";
import { SyncInfoStatus } from "./SyncInfoStatus.component";

export function DocumentSummary({ uacProjectId }: { uacProjectId: string }) {
  const { uacProjectStatus, hasSyncedFolders } = useUACProjectStatus({
    uac_project_id: uacProjectId,
  });

  return (
    <Stack gap={8}>
      {uacProjectStatus.documents_status.total > 0 ? (
        <div>
          <div className="flex justify-between gap-2 flex-wrap">
            <SyncInfoStatus
              displayName={uacProjectStatus.uac_project.name}
              documentStatusInfo={uacProjectStatus.documents_status}
            />
          </div>
          {hasSyncedFolders && (
            <PageSection header="Synced Directories">
              <DirectoryExplorer
                key={JSON.stringify(uacProjectStatus.uac_project_folders)}
                directory={uacProjectStatus.uac_project_folders}
              />
            </PageSection>
          )}
        </div>
      ) : (
        <div className="text-center text-gray-600 mb-4">
          No documents synced
        </div>
      )}
    </Stack>
  );
}
